<template>
    <div class="search_list">
        <div class="flex-between-center px-3 my-3">
            <small>총 <b>{{list.length}}</b>개</small>
        </div>
        <div>
            <ul>
                <li v-for="(item, index) in list" :key="index" class="py-4 bg-body px-3 my-2">
                    <div @click="OpenDetail(index)" class="d-flex align-items-center position-relative">
                        <div class="bg-img rounded-4 w-px-90 h-px-90 flex-shrink-0 me-3" :style="`background-image: url(${item.img})`"></div>
                        <div class="flex-grow-1 txt_box small w-80">
                            <div class="badges mb-2">
                                <ul class="small">
                                    <li class="badge bg-gray-relative-200 text-body rounded-1 me-1 fw-light" v-for="(tag,tab_index) in item.tags" :key="tab_index">{{tag}}</li>
                                </ul>
                            </div>
                            <p class="h6 fw-bold mb-1">{{item.title}}</p>
                            <p class="small lh-sm text-gray-relative-500"><i class="far fa-map-marker-alt me-1"></i>{{item.address}}</p>
                            <!-- <div class="d-flex lh-sm mt-2">
                                ====활성화시 thumbnail 110px
                                <a 
                                :href="`tel:${item.contact}`" 
                                v-if="item.contact != ''" 
                                class="fw-bold border border-gray-relative-200 text-center w-100 py-2 rounded-start" 
                                :class="{'rounded-end':!item.reserve}"
                                ><i class="fas fa-phone-alt text-gray-relative-600 small me-1"></i> 전화</a>
                                <p 
                                v-if="item.reserve" 
                                class="fw-bold text-main border border-gray-relative-200 ms-px--1 text-center w-100 py-2 rounded-end"
                                :class="{'rounded-start':item.contact==''}"
                                @click.prevent="$refs.ReservationPopup.pp = true"
                                ><i class="far fa-calendar-check me-1"></i> 예약</p>
                            </div> -->
                        </div>
                        <i class="fa-bookmark align-self-start position-absolute top-0 end-0 bg-body bg-opacity-50" :class="{'fas text-danger':item.bookmark, 'fal text-gray-relative-500':!item.bookmark}" @click.prevent.stop="item.bookmark = !item.bookmark"></i>
                    </div>
                </li>
            </ul>
        </div>

		<SearchDetailPopup ref="SearchDetailPopup"  />
		<ReservationPopup ref="ReservationPopup" />
    </div>
</template>

<script>

import SearchDetailPopup from '@/components/popup/SearchDetail.vue'
import ReservationPopup from '@/components/popup/Reservation.vue'
export default {
    components:{
        ReservationPopup,SearchDetailPopup
    },
    props: {
        list:{
            type:Array,
            default: function(){
                return [];
            }
        },
    },
    data(){
        return{
        }
    },
    methods:{
        OpenDetail(idx){
            this.$refs.SearchDetailPopup.item = this.list[idx];
            this.$refs.SearchDetailPopup.pp = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .search_list{
        min-height: calc(100vh - 141px);
    }
</style>