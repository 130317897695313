<template>
    <div class="basic_pp bottom_up" v-if="pp"  :class="{'off':off}">
        <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
        <div class="content bg-body">
            <div class="header position-sticky flex-between-center pb-3">
                <h5 class="text-truncate">{{terms[terms_idx].title}}</h5>
                <i class="fal fa-times p-2" @click="pp_off()"></i>
            </div>
            <div class="body overflow-auto" ref="basic_pp_body">
                {{terms[terms_idx].desc}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
			terms: [
                {
                    title:"운영 정책 동의",
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat porro voluptatum harum aliquam deserunt dignissimos totam modi beatae. Repellat repellendus, autem placeat perspiciatis laboriosam in! Libero sequi expedita necessitatibus ex beatae veritatis odit corrupti, quae culpa ipsam doloribus vitae similique? Ad, labore. Animi, delectus magni obcaecati quos ullam, assumenda, eum similique placeat autem sunt natus quisquam voluptatem est ab ratione molestiae incidunt voluptas sit maiores voluptatum expedita iure corrupti! Illum quis voluptatem accusantium nihil a tenetur quam quod. Numquam, repudiandae.',
                },
                {
                    title:"이용자 약관 정보 동의",
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat porro voluptatum harum aliquam deserunt dignissimos totam modi beatae. Repellat repellendus, autem placeat perspiciatis laboriosam in! Libero sequi expedita necessitatibus ex beatae veritatis odit corrupti, quae culpa ipsam doloribus vitae similique? Ad, labore. Animi, delectus magni obcaecati quos ullam, assumenda, eum similique placeat autem sunt natus quisquam voluptatem est ab ratione molestiae incidunt voluptas sit maiores voluptatum expedita iure corrupti! Illum quis voluptatem accusantium nihil a tenetur quam quod. Numquam, repudiandae.',
                },
                {
                    title:"개인정보 처리방침 동의",
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat porro voluptatum harum aliquam deserunt dignissimos totam modi beatae. Repellat repellendus, autem placeat perspiciatis laboriosam in! Libero sequi expedita necessitatibus ex beatae veritatis odit corrupti, quae culpa ipsam doloribus vitae similique? Ad, labore. Animi, delectus magni obcaecati quos ullam, assumenda, eum similique placeat autem sunt natus quisquam voluptatem est ab ratione molestiae incidunt voluptas sit maiores voluptatum expedita iure corrupti! Illum quis voluptatem accusantium nihil a tenetur quam quod. Numquam, repudiandae.',
                },
                {
                    title:"E-mail 및 SMS광고성 정보 수신 동의",
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat porro voluptatum harum aliquam deserunt dignissimos totam modi beatae. Repellat repellendus, autem placeat perspiciatis laboriosam in! Libero sequi expedita necessitatibus ex beatae veritatis odit corrupti, quae culpa ipsam doloribus vitae similique? Ad, labore. Animi, delectus magni obcaecati quos ullam, assumenda, eum similique placeat autem sunt natus quisquam voluptatem est ab ratione molestiae incidunt voluptas sit maiores voluptatum expedita iure corrupti! Illum quis voluptatem accusantium nihil a tenetur quam quod. Numquam, repudiandae.',
                },
            ],

            // front ui
			pp: false,
			off: false,
			terms_idx: -1,
            timeout_id: null,
        }
    },
    methods: {

        // front ui
		pp_on(idx){
			this.terms_idx = idx;
			this.pp = true;
		},
        pp_off(){
            this.off = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;}
                this.timeout_id = null;
            }, 200);
        }
    }
}
</script>

<style>

</style>