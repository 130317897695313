<template>
    <div class="search_list bg-gray-relative-100">
        <SubHeader title="국내골프장 검색" :link="'/home'" class="bg-body" style="padding-bottom:42px"></SubHeader>

		<div>
			<div id="search_box" class="search_box position-sticky zindex-1 pt-3 pb-3 border-bottom border-gray-relative-200 bg-body px-3">
				<select class="form-control thick bg-body border-gray-relative-300 mb-1" :class="{'text-gray-500':location==null, 'text-body':location!=null}" v-model="location">
					<option class="text-body" v-for="(item,index) in location_list" :key="index" :value="item.value">{{item.name}}</option>
				</select>
				<div class="position-relative mb-1">
					<input class="form-control thick text-body bg-body border-gray-relative-300" type="text" placeholder="매장명" v-model="search">
					<div class="position-absolute end-0 top-50 translate-middle-y">
						<div class="px-3 small" v-if="search==''"><i class="far fa-search text-gray-500"></i></div>
						<div class="px-3 small" v-else @click="search=''"><i class="fas fa-times-circle text-gray-500"></i></div>
					</div>
				</div>
				<div class="badges">
					<ul class="d-flex w-100 overflow-auto pt-1">
						<li @click="resetFn()"><span class="badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{'bg-gray-relative-200':alltag}">전체</span></li>
						<li v-for="(item,index) in tag" :key="index" @click="selectFn(item)">
							<span class="badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{'bg-gray-relative-200':item.activate}">{{item.type}}</span></li>
					</ul>
				</div>
			</div>

			<SearchList :list="list" />
		</div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import SearchList from '@/components/search/SearchList.vue'

export default {
    components:{
        SubHeader, SearchList
    },
    data(){
        return{
			search: "",
			alltag:true,
			tag:[
				{
					type:'tag',
					activate:false
				},
				{
					type:'회원제',
					activate:false
				},
				{
					type:'평점 높음',
					activate:false
				},
				{
					type:'스크린',
					activate:false
				},
				{
					type:'추천',
					activate:false
				},
				{
					type:'some',
					activate:false
				},
			],
			location_list: [
				{
					value: null,
					name: "시/도 선택",
				},
				{
					value: 0,
					name: "전체",
				},
				{
					value: 1,
					name: "강원",
				},
				{
					value: 2,
					name: "경기",
				},
				{
					value: 3,
					name: "경남",
				},
				{
					value: 4,
					name: "경북",
				},
				{
					value: 5,
					name: "광주",
				},
				{
					value: 6,
					name: "대구",
				},
				{
					value: 7,
					name: "대전",
				},
				{
					value: 8,
					name: "부산",
				},
				{
					value: 9,
					name: "서울",
				},
				{
					value: 10,
					name: "세종",
				},
				{
					value: 11,
					name: "인천",
				},
				{
					value: 12,
					name: "전남",
				},
				{
					value: 13,
					name: "전북",
				},
				{
					value: 14,
					name: "제주",
				},
				{
					value: 15,
					name: "충남",
				},
			],
			location: null,

			
			list: [
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '일산 스프링 힐스',
					address: '대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.somesite.com',
					tags: ['some','tag'],
					bookmark: false,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213',
					contact: '',
					site: 'www.naver.com',
					tags: ['태그','미정'],
					bookmark: false,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1555177791-8ce0b098915a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: '구월 골프파크',
					address: '대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: [],
					bookmark: false,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1564431297866-ff1306687c8e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: '풍산 제일스크린',
					address: '대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: ['some'],
					bookmark: false,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213 대전 광역시 대전로 213 대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: [],
					bookmark: true,
					reserve: true,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: '골프파크 CC',
					address: '대전 광역시 대전로 213',
					contact: '',
					site: 'www.naver.com',
					tags: [],
					bookmark: false,
					reserve: false,
					country: 'kr',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1564431297866-ff1306687c8e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: '스카이골프 CC',
					address: '대전 광역시 대전로 213',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: ['tag'],
					bookmark: false,
					reserve: false,
					country: 'kr',
				},
			],
			
		}
    },
	methods:{
		resetFn(){
			this.tag.forEach( item => {
				item.activate = false;
			})
			this.alltag = true;
		},
		selectFn(item){
			if(this.alltag){
				this.alltag = false;
			}
			item.activate = !item.activate;
		}
	}
}
</script>