<template>
    <div class="search_list bg-gray-relative-100">
        <SubHeader title="해외골프장 검색" :link="'/home'" class="bg-body" style="padding-bottom:42px"></SubHeader>

        <div>
          <div id="search_box" class="search_box position-sticky zindex-1 pt-3 pb-3 border-bottom border-gray-relative-200 bg-body px-3">
            <select class="form-control thick bg-body border-gray-relative-300 mb-1" :class="{'text-gray-500':location==null, 'text-body':location!=null}" v-model="location">
              <option class="text-body" v-for="(item,index) in location_list" :key="index" :value="item.value">{{item.name}}</option>
            </select>
            <div class="position-relative mb-1">
              <input class="form-control thick text-body bg-body border-gray-relative-300" type="text" placeholder="매장명" v-model="search">
              <div class="position-absolute end-0 top-50 translate-middle-y">
                <div class="px-3 small" v-if="search==''"><i class="far fa-search text-gray-500"></i></div>
                <div class="px-3 small" v-else @click="search=''"><i class="fas fa-times-circle text-gray-500"></i></div>
              </div>
            </div>
			<div class="badges">
				<ul class="d-flex w-100 overflow-auto pt-1">
					<li @click="resetFn()"><span class="badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{'bg-gray-relative-200':alltag}">전체</span></li>
					<li v-for="(item,index) in tag" :key="index" @click="selectFn(item)">
						<span class="badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{'bg-gray-relative-200':item.activate}">{{item.type}}</span></li>
				</ul>
			</div>
          </div>

          <SearchList :list="list" />
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import SearchList from '@/components/search/SearchList.vue'

export default {
    components:{
        SubHeader, SearchList
    },
    data(){
        return{
			search: "",

			alltag:true,
			tag:[
				{
					type:'tag',
					activate:false
				},
				{
					type:'회원제',
					activate:false
				},
				{
					type:'평점 높음',
					activate:false
				},
				{
					type:'스크린',
					activate:false
				},
				{
					type:'추천',
					activate:false
				},
				{
					type:'some',
					activate:false
				},
			],

			location_list: [
				{
					value: null,
					name: "국가 선택",
				},
				{
					value: 1,
					name: "일본",
				},
				{
					value: 2,
					name: "중국",
				},
				{
					value: 3,
					name: "미국",
				},
				{
					value: 4,
					name: "베트남",
				},
				{
					value: 5,
					name: "대만",
				}
			],
			location: null,

			
			list: [
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1555177791-8ce0b098915a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: ['some','tag'],
					bookmark: false,
					reserve: true,
					country: 'us',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534',
					contact: '',
					site: 'www.naver.com',
					tags: ['태그','미정'],
					bookmark: false,
					reserve: true,
					country: 'us',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: [],
					bookmark: false,
					reserve: true,
					country: 'us',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1555177791-8ce0b098915a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: ['some'],
					bookmark: false,
					reserve: true,
					country: 'us',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1564431297866-ff1306687c8e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534 2880 Ave, Brooklyn, NY 12534 2880 Ave, Brooklyn, NY 12534',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: [],
					bookmark: true,
					reserve: true,
					country: 'us',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534',
					contact: '',
					site: 'www.naver.com',
					tags: [],
					bookmark: false,
					reserve: false,
					country: 'us',
				},
				{
					id: 0,
					img: 'https://images.unsplash.com/photo-1564431297866-ff1306687c8e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
					title: 'Marine Park Golf course',
					address: '2880 Ave, Brooklyn, NY 12534',
					contact: '000-000-0000',
					site: 'www.naver.com',
					tags: ['tag'],
					bookmark: false,
					reserve: false,
					country: 'us',
				},
			],
			
		}
    },
	methods:{
		resetFn(){
			this.tag.forEach( item => {
				item.activate = false;
			})
			this.alltag = true;
		},
		selectFn(item){
			if(this.alltag){
				this.alltag = false;
			}
			item.activate = !item.activate;
		}
	}
}
</script>