<template>
    <!-- 예약팝업 -->
    <div v-show="pp">
        <div class="basic_pp bottom_up" v-show="pp" :class="{'off':off}">
            <div class="dimm bg-body-i bg-opacity-50" @click="CloseReserve()"></div>
            <div class="content bg-body overflow-hidden">
                <div class="header position-sticky flex-between-center pb-3 text-truncate">
                    <h5>예약하기</h5>
                    <i class="p-2 fal fa-times" @click="CloseReserve()"></i>
                </div>
                <div class="body overflow-auto pt-1" ref="basic_pp_body">
                    <div>
                        <div class="bg-body">
                            <div class="d-flex align-items-center position-relative">
                                <div class="bg-img rounded-4 w-px-90 h-px-90 flex-shrink-0 me-3" :style="`background-image: url(${item.img})`"></div>
                                <div class="flex-grow-1 txt_box small w-80">
                                    <div class="badges mb-2">
                                        <ul class="small">
                                            <li class="badge bg-gray-relative-200 text-body rounded-1 me-1 fw-light" v-for="(tag,tab_index) in item.tags" :key="tab_index">{{tag}}</li>
                                        </ul>
                                    </div>
                                    <p class="h6 fw-bold mb-1">{{item.title}}</p>
                                    <p class="small lh-sm text-gray-relative-500"><i class="far fa-map-marker-alt me-1"></i>{{item.address}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cutting_line border-gray-relative-300 w-100 h-0 my-4"></div>
                    <div class="mb-5 pb-3">
                        <h6 class="mb-3 fw-bold"><i class="far fa-user-circle"></i> 예약자 정보</h6>
                        <label class="text-gray-relative-600 small ms-2">성함</label>
                        <input type="text" class="form-control thick text-body border-gray-relative-300 bg-transparent fs-6 fw-bold mb-3" placeholder="예약자 성함" v-model="user.name">
        
                        <label class="text-gray-relative-600 small ms-2">연락처</label>
                        <input type="tel" class="form-control thick text-body border-gray-relative-300 bg-transparent fs-6 fw-bold" placeholder="예약자 연락처" v-model="user.contact">
                    </div>
                    <div class="mb-5">
                        <div class="mb-3 flex-between-center">
                            <h6 class="fw-bold"><i class="far fa-calendar-alt"></i> 예약 날짜</h6>
                            <span class="fw-bold text-main bg-gray-relative-100 px-3 py-1 rounded-pill" v-if="day!=null">{{DateFormmat(day)}}
                                <i class="far fa-times ms-2 text-gray-relative-600 small" @click="ResetDate()"></i>
                            </span>
                            <small class="text-gray-relative-600" v-else>날짜를 선택해주세요</small>
                        </div>
                        <Calendar :min-date='new Date()' :max-date='MaxDate()' :is-dark="this.$store.state.theme == 'dark'" :attributes="attributes" @dayclick="onDayClick" class="mb-4 border-gray-pp bg-body bg-opacity-25" />
                    </div>
                    <div class="mb-5">
                        <h6 class="mb-3 fw-bold"><i class="far fa-clock"></i> 예약 시간</h6>
                        <div class="radio_btn_box">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="radio1_1" v-model="reserve_time" value="1">
                                <label class="form-control thick bg-body text-body border-gray-relative-300 fw-bold fs-6" for="radio1_1">오전</label>
                            </div>
                            <div class="form-check ms-1">
                                <input class="form-check-input" type="radio" id="radio1_2" v-model="reserve_time" value="2">
                                <label class="form-control thick bg-body text-body border-gray-relative-300 fw-bold fs-6" for="radio1_2">오후</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="round_checkbox pb-3">
                            <input type="checkbox" v-model="terms_check1" id="check1">
                            <label for="check1">
                                <div class="chk_icon me-2"></div>
                                <small class="conditions text-truncate lh-1">개인정보 제3자 수집/제공 동의</small>
                            </label>
                            <small class="text-decoration-underline ms-3 text-gray-relative-600" @click="$refs.TermsPopup.pp_on(0)">보기</small>
                        </div>
                        <div class="btn thick btn-main text-white w-100" :class="{'disabled':!terms_check1}" @click="Reserve()">예약하기</div>
                    </div>
                </div>
            </div>
        </div>

        <TermsPopup ref="TermsPopup" />
    </div>
</template>

<script>
import TermsPopup from '@/components/popup/Terms.vue'
import { Calendar, DatePicker } from 'v-calendar';

// import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    components:{
		Calendar,
    	DatePicker,
        TermsPopup
    },
    data(){
        return{
			user: {
				name: '홍길동',
				contact: '01012345678',
			},
            item: {
                id: 0,
                img: 'https://images.unsplash.com/photo-1610916445778-09401a50a2b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
                title: '스카이골프 CC',
                address: '대전 광역시 대전로 213',
                contact: '000-000-0000',
                site: 'www.naver.com',
                tags: ['some','tag'],
                bookmark: false,
                reserve: true,
            },

			reserve_time: 1,
			day: null,
			attributes: [],

			terms_check1: false,

            // front ui
			pp: false,
			off: false,
            timeout_id: null,
        }
    },
	watch: {
		pp : function(){
			if( this.pp == false ){
				this.ResetDate();
			}
		},
	},
	methods: {
        CheckForm(){
            let confirm = true;

            if( this.user.name == '' ){
                this.$alert("예약자 성함을 입력해주세요.");
                confirm = false;
                return false;
            }
            if( this.user.contact == '' ){
                this.$alert("예약자 연락처를 입력해주세요.");
                confirm = false;
                return false;
            }
            if( this.day == null ){
                this.$alert("예약 날짜를 선택해주세요.");
                confirm = false;
                return false;
            }

            return confirm;
        },
        Reserve(){
            if( this.CheckForm() ){
                this.$confirm(`'${this.DateFormmat(this.day)} ${this.TimeFormmat(this.reserve_time)}'<br>예약하시겠습니까?`).then((result)=>{
                    if( result.isOk ){
                        this.$alert('예약을 요청했습니다.<br>매장 측 승인 후 예약이 완료됩니다.').then(()=>{this.$router.push('/mypage/reservation')});
                    }else if( result.isDeny ){
                        this.$alert('취소되었습니다.');
                    }
                })
            }
        },
        CloseReserve(){
            this.$confirm('예약을 취소하시겠습니까?').then((result)=>{
                if( result.isOk ){
                    this.pp_off();
                }
            })
        },

        // 캘린더 관련==================================
		onDayClick(day) {
			if( !day.isDisabled ){
				this.day = day.date;
				if( this.attributes.length > 1 ){
					const popResult = this.attributes.pop();
				}
				this.attributes.push({
					key: 'selected_day',
					highlight: 'green',
					dates: this.day
				})
			}
		},
		MaxDate(){
			let today = new Date();
			return new Date(today.setMonth(today.getMonth() + 1))
		},
		ResetDate(){
			this.day = null;
			this.attributes = [
				{
					key: 'today',
					dot: true,
					dates: new Date()
				}
			];
            this.reserve_time = 1;
            this.terms_check1 = false;
		},
		DateFormmat(date_str){
			let date = new Date(date_str);
			let month= date.getMonth() +1;  
			let day  = date.getDate();
			return month+"월 "+day+"일"
		},
		TimeFormmat(time_str){
            if(time_str == 1){
                return '오전'
            }else{
                return '오후'
            }
		},
        // 캘린더 관련 end ==============================


        // front ui
        pp_off(){
            this.off = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;}
                this.timeout_id = null;
            }, 200);
        }
	},
    
	mounted(){
		this.ResetDate();
	}
}
</script>

<style lang="scss" scoped>

	.vc-container{
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		display: block;
		background-color: transparent;
	}
</style>